import React, { Component } from "react";
import { Link } from "gatsby";

import Layout from "../layouts/zh";
import SEO from "../components/seo";

export default class TermsAndConditionsPage extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const specific_date = new Date("2022-01-09");
    const current_date = new Date();
    const url = this.props.location.href;
    const sectionUrl = url?.split("#")[1] ?? "";
    //scroll to section if have the sectionURL
    if (sectionUrl) {
      const element = document.getElementById(sectionUrl);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (current_date.getTime() > specific_date.getTime()) {
      return (
        <Layout location={this.props.location}>
          <SEO
            title="Terms and Conditions "
            keyword="Terms and Conditions"
            decription="Terms and Conditions"
          />

          <div className="terms">
            <div className="container container--1000">
              <h1 className="text--center">条款和条件</h1>
              <p className="p--sm">更新于 10月 2024年</p>
              <p className="p--sm">
                以下是PT AYANA Hotel Manajemen（称为“公司”或“AYANA
                Hospitality”）与AYANA
                Rewards计划会员之间的合同协议修订条款和条件。
                这些更新后的条款和条件将于[月]
                [日]，[年]（称为“生效日期”）生效，以替代之前的AYANA
                Rewards计划的条款和条件。 自生效日期或之后继续作为AYANA
                Rewards计划会员参与，即表示您接受以下列出的更新条款和条件。
              </p>
              <p className="p--sm">
                阿雅娜奖励（AYANA Rewards
                ）是一个忠诚度计划，旨在用无与伦比的AYANA体验回馈您独到的品味。开启一个为少数特权人士精心打造的个性化体验世界，享受诸如免费早餐、延迟退房和优先房间升级等独家福利。沉浸于AYANA传奇的工艺和巧妙的服务中，发现与我们迷人目的地的更深层次的联系。
              </p>
              <p className="p--sm" style={{ marginBottom: "24px" }}>
                以下条款和条件旨在确保我们尊贵的会员获得最佳体验。
              </p>
              <Link
                id="general-terms-conditions"
                href="#general-terms-conditions"
                style={{
                  scrollMarginTop: "120px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                }}>
                一般条款和条件：
              </Link>
              <ol style={{ marginTop: "7.5px", marginBottom: "24px" }}>
                <li>
                  当个人信息变更时，会员有责任在AYANA
                  Rewards会员门户中更新其会员记录或书面通知AYANA
                  Rewards会员服务。
                </li>
                <li>
                  AYANA
                  Rewards保留在未通知的情况下修改规则、限制、计划权益、兑换奖励和特别优惠的权利。AYANA
                  Rewards保留在任何时候终止AYANA Rewards计划的权利。
                </li>
                <li>
                  AYANA Rewards对于通过AYANA
                  Rewards所要求、兑换和/或享有的商品或服务的质量不作任何明示或暗示的保证或声明，并明确表示不承担任何责任（包括间接损害）。法律暗示的所有条件、保证或其他条款，在适用法律和法规允许的最大范围内被排除。如任何会员转让任何奖励证书的使用，本条款亦适用于受让人。
                </li>
                <li>
                  参与酒店和/或AYANA Rewards保留在必要时调整AYANA
                  Rewards积分值的权利，以反映市场条件的变化。
                </li>
                <li>
                  有关AYANA
                  Rewards或奖励使用的欺诈或滥用行为，将由参与酒店和/或AYANA
                  Rewards的合作伙伴采取适当的行政和/或法律行动。
                </li>
                <li>在法律禁止的情况下，此计划无效。</li>
                <li>AYANA Rewards忠诚度计划是由参与酒店运营的计划。</li>
                <li>
                  计划条款和条件的所有解释均由AYANA Rewards全权决定。
                  在任何争议发生时，会员同意提交给印度尼西亚法院的专属司法管辖权。
                </li>
              </ol>
              <Link
                id="glossary"
                href="#glossary"
                style={{
                  scrollMarginTop: "120px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                }}>
                1.词汇表
              </Link>
              <ol type="A" style={{ marginTop: "7.5px", marginBottom: "24px" }}>
                <li>
                  <span style={{ fontWeight: "700" }}>活跃状态: </span>
                  指在最近一次记录活动的日期起24个月内，参与过网站登录活动、完成过符合条件的住宿、进行过符合条件的消费，或兑换过AYANA
                  Rewards积分的个人。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>奖励积分: </span>
                  通过参与物业的合资格房价和/或符合条件的费用赚取的积分，包括但不限于促销优惠和基于等级的激励。这些积可根据供应情况和计划的条款和条件兑换独家体验。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>日历年: </span>
                  从会员注册、升级、更新或降级到当前会员等级的日期起，每个日历年的1月1日至12月31日期间。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>等级积分: </span>
                  赚取的积分，用于获得下一个更高级别的资格或保留当前级别。等级积分不包括作为促销优惠或会员状态的一部分授予的奖励积分。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>合资格住宿夜数: </span>
                  指通过合资格房价和/或合资格房间预订渠道获得的有助于会员奖励状态的住宿夜数。住宿的总夜数由预订的房间数和住宿时间决定。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>合资格房价: </span>
                  任何可在 https://www.ayana.com/, https://rewards.ayana.com/,
                  https://www.delonixhotel.com/
                  上找到的参与品牌的公布房价，或通过合资格预订渠道预订。参与品牌保留自行决定将某些房价不时指定为合资格房价的权利。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>合资格预订渠道: </span>
                  合资格预订渠道包括酒店预订, https://www.ayana.com/,
                  https://rewards.ayana.com/, 和 https://www.delonixhotel.com/
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>合资格附加消费: </span>
                  指在参与品牌管理的参与餐厅、温泉或休闲设施上的任意消费，无论会员被分类为酒店客人还是非酒店客人。参与品牌保留自行决定在某个时间将某些交易指定为合资格交易的权利。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>不合资格预订渠道: </span>
                  包括第三方平台，在线服务提供商，在线旅游代理，批发商，组织者或策划者，以及线下旅行代理。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>不合资格房价: </span>
                  这些包括通过第三方网站如在线旅行代理，线下旅行代理和批发商预订的房间。此类别还包括为团体，婚礼，活动和会议，航空公司机组人员，家人和朋友，免费住宿，免费晚奖励，记入总帐的房价，佣金房价及其他未明确提及的特殊房价预订的房间。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>品牌: </span>
                  任何为AYANA
                  Rewards提供服务的参与餐厅、温泉或合作伙伴（第三方）。
                </li>

                <li>
                  <span style={{ fontWeight: "700" }}>用积分支付: </span>
                  指一项功能，会员可以利用AYANA奖励积分预订合资格的房价。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>兑换券: </span>
                  指任何可使用所需积分兑换的免费住宿奖励、餐饮、温泉或其他类别的代金券。
                </li>
              </ol>
              <Link
                id="membership-eligibility"
                href="#membership-eligibility"
                style={{
                  scrollMarginTop: "120px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                }}>
                2. 会员资格
              </Link>
              <ol style={{ marginTop: "7.5px", marginBottom: "24px" }}>
                <li>
                  所有个人客人都有资格成为AYANA Rewards会员。AYANA
                  Rewards会员资格不可转让。会员不得在多个账户上维持会员资格或赚取积分。
                </li>
                <li>
                  为有资格获得AYANA Rewards积分，AYANA
                  Rewards会员必须保持活跃状态。
                </li>
                <li>
                  未能保持会员的活跃状态将导致其会员资格的取消，导致任何累计积分的丧失，而不论其到期日如何。
                </li>
                <li>
                  AYANA
                  Rewards保留拒绝或终止会员资格的权利，无需提供理由，或在未准确完整地提供所需信息的情况下拒绝任何申请。
                </li>
              </ol>
              <Link
                id="membership-levels"
                href="#membership-levels"
                style={{
                  scrollMarginTop: "120px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                }}>
                3. 会员级别
              </Link>
              <ol style={{ marginTop: "7.5px", marginBottom: "24px" }}>
                <li>
                  AYANA
                  Rewards提供三个会员级别：银卡、金卡和白金卡，其中银卡是入门级别。
                </li>
                <li>
                  下表列出了保持或升级会员级别所需的合资格住宿夜数或等级积分：
                </li>
                <table>
                  <tr style={{ height: "25%" }}>
                    <th
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      级别
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      合资格夜数
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        verticalAlign: "middle",
                        textAlign: "center",
                        wordBreak: "break-all",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                      rowspan="4">
                      或
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      等级积分
                    </th>
                  </tr>
                  <tr style={{ height: "25%" }}>
                    <td
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid black",
                        textAlign: "center",
                      }}>
                      银卡
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}>
                      不适用
                    </td>
                    <td style={{ padding: "10px", textAlign: "center" }}>
                      或 | 不适用
                    </td>
                  </tr>
                  <tr style={{ height: "25%" }}>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}>
                      金卡
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}>
                      20 | 或
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}>
                      8,000
                    </td>
                  </tr>
                  <tr style={{ height: "25%" }}>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}>
                      白金卡
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}>
                      50 | 或
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}>
                      20,000
                    </td>
                  </tr>
                </table>
                <li>
                  会员必须在他们上次升级或降级后的下一个日历年的12月31日之前维持所需的积分或合资格夜数。不满足这些最低要求将导致会员级别的降级。
                </li>
                <li>
                  AYANA会员级别在上次升级或降级或保留后的下一个日历年的12月31日之前保持有效。
                </li>
                <li>
                  合资格住宿夜数或等级积分仅用于确定等级状态，该标准可能随时由AYANA
                  Rewards自行决定和更改。
                </li>
                <li>
                  AYANA
                  Rewards保留在未通知的情况下随时更改会员升级和续订资格标准的权利。
                </li>
              </ol>
              <Link
                id="member-benefits"
                href="#member-benefits"
                style={{
                  scrollMarginTop: "120px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                }}>
                4.会员福利
              </Link>
              <h3>4.1 一般条件</h3>
              <ol style={{ marginBottom: "24px" }}>
                <li>
                  达到AYANA Rewards金卡或AYANA
                  Rewards白金卡状态的会员享有与酒店住宿和非酒店住宿相关的福利，详见下表：
                </li>
                <table>
                  <tr>
                    <th
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      福利
                    </th>
                    <th
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      银卡
                    </th>
                    <th
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      金卡
                    </th>
                    <th
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      白金卡
                    </th>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      会员专属房间折扣
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        fontWeight: "700",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      合资格
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      合资格
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      合资格
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      同一预订房间类别的免费一次房间升级
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      不合资格
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      合资格
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      合资格
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      合资格消费的奖励积分
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      不合资格
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      25%
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      50%
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      提前入住
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      不合资格
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      11:00
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      11:00
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      延迟退房
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      不合资格
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      16:00
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      18:00
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      免费一次早餐
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      不合资格
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                      }}>
                      不合资格
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      2人
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      欢迎礼物
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      不合资格
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      合资格
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      合资格
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      生日期间的免费设施
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}>
                      合资格
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      合资格
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      合资格
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      参与品牌的会员专属折扣
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}>
                      合资格
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      合资格
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      合资格
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      健康计划福利
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}>
                      合资格
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      合资格
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      合资格
                    </td>
                  </tr>
                </table>
                <li>会员的福利不可转让，仅可由会员本人索取。</li>
                <li>
                  AYANA Rewards金卡会员在合资格消费上有25%的奖励积分，AYANA
                  Rewards白金会员则享有50%的奖励积分。然而，在Delonix Residences
                  Karawang的交易是例外，AYANA
                  Rewards金卡会员对这些交易获得10%的奖励，而AYANA
                  Rewards白金会员获得25%的奖励。
                </li>
                <li>
                  AYANA Rewards会员有权在参与品牌享有专属房间折扣。
                  <ol type="a">
                    <li>
                      通过 https://rewards.ayana.com/
                      进行预订并以会员身份登录时，会员有权享受会员专属房间折扣。
                    </li>
                    <li>
                      如果在预订房间后才注册或者未在预订参与品牌的房间时登录，会员无权要求会员专用的房间折扣。
                    </li>
                  </ol>
                </li>
                <li>AYANA Rewards会员的福利可能会受到可用性和封锁期的限制。</li>
                <li>
                  AYANA
                  Rewards保留在未通知会员的情况下修改或撤回任何福利的权利。
                </li>
              </ol>
              <h3>4.2 酒店住宿会员福利</h3>
              <ol>
                <li>
                  AYANA
                  Rewards会员特定于酒店住宿的福利不适用于居住在住宿处或进行长期居住（超出或等于30连续夜晚）的人。
                </li>
                <li>
                  会员在酒店住宿期间必须占用至少一个房间以享有会员福利。如果会员与其他会员共享一个房间，则只有主预订注册的会员有资格享有会员福利。如果会员预订了多个房间，所有会员福利仅适用于会员实际上使用的房间。
                </li>
                <li>
                  即使会员在同一位置连续预订不同酒店或房型，酒店住宿相关的福利（如免费早餐和欢迎礼物）也仅在会员的最初入住期间适用，无论会员是否退房并立即重新办理入住手续。
                </li>
                <li>
                  与酒店住宿相关的会员福利包括：
                  <ol type="a">
                    <li>
                      同一房型免费1级房间升级
                      <ol type="i">
                        <li>
                          免费1级房间升级每位会员每次入住最多适用于1次房间升级。
                        </li>
                        <li>
                          此升级在同一房间类别中有效，不可用于不同房间类别的升级（例如，从普通房到套房，从套房到别墅等）。
                        </li>
                        <li>
                          此福利不适用于预订公寓、多个卧室房间和别墅的会员。
                        </li>
                        <li>
                          免费1级房间升级的可用性依赖于会员到达时参与酒店的可用性。
                        </li>
                      </ol>
                      <li>
                        提前入住和/或延迟退房
                        <ol type="i">
                          <li>
                            到达时，会员有权根据参与酒店的空房情况享受提前入住和/或延迟退房服务。
                          </li>
                          <li>
                            建议至少在会员到达前2天提供航班信息和/或预计到达时间以申请11.00的提前入住
                          </li>
                          <li>建议在到达时在接待处申请延迟退房。</li>
                        </ol>
                        <li>
                          生日入住期间的免费设施
                          <br />
                          会员有权在生日入住期间收到免费生日设施。免费设施可能因参与酒店的不同而有所不同。
                        </li>
                        <li>
                          健康计划福利
                          <ol type="i">
                            <li>
                              在参与酒店住宿期间，会员有资格获得健康计划福利。如果多个会员住在同一个房间，则此福利仅适用于主要房间预订中注册的会员。
                            </li>
                            <li>福利可能因参与酒店的不同及地点而有所不同。</li>
                          </ol>
                        </li>
                        <li>
                          免费一次早餐
                          <ol type="i">
                            <li>
                              通过合资格预订渠道预订不含早餐的房间的AYANA
                              Rewards白金卡会员可享受免费早餐。这一优惠适用于会员和一名随行人员（1人），无论他们是成人还是儿童。会员必须出示酒店提供的凭证才能享用免费早餐。凭证一旦遗失，将不予补发。
                            </li>
                          </ol>
                        </li>
                        <li>
                          欢迎礼物
                          <ol type="i">
                            <li>
                              AYANA
                              Rewards金卡和白金卡会员在每次入住期间都会获得欢迎礼物。具体提供的礼物可能因参与酒店的不同及地点而有所不同。
                            </li>
                            <li>
                              欢迎礼物仅提供给通过合资格预订渠道预订的会员。
                            </li>
                            <li>退房前未使用的欢迎礼物将视为放弃。</li>
                            <li>凭证一旦遗失，将不予补发。</li>
                          </ol>
                        </li>
                      </li>
                    </li>
                  </ol>
                </li>
              </ol>
              <h3>4.3 会员福利 - 非酒店住宿 </h3>
              <ol type="1" style={{ marginBottom: "24px" }}>
                <li>
                  在参与品牌享有会员专属折扣
                  <ol type="a">
                    <li>
                      会员可以通过出示其AYANA
                      Rewards会员资料，在参与品牌享受专属折扣。但请注意，每个参与品牌可能适用特定条款和条件。
                    </li>
                    <li>
                      支付时未出示AYANA
                      Rewards会员资料将导致无法享受参与品牌的专属折扣。这些折扣不能在酒店前台结账时应用。
                    </li>
                  </ol>
                </li>
              </ol>
              <Link
                id="points-earning"
                href="#points-earning"
                style={{
                  scrollMarginTop: "120px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                }}>
                5. 积分赚取
              </Link>
              <h3>5.1 一般条件</h3>
              <ol style={{ marginBottom: "24px" }}>
                <li>
                  会员在参与品牌的每消费10,000印尼盾将获得1（一个）AYANA
                  Rewards积分，Delonix Hotel Karawang、Delonix Residences
                  Karawang和Riverside高尔夫俱乐部除外。在这些地点，会员将在每消费15,000印尼盾的合资格费用（不包括税费和服务费）中获得1（一个）AYANA
                  Rewards积分。参与品牌包括AYANA Midplaza Jakarta、AYANA Komodo
                  Waecicu Beach、AYANA Segara Bali、AYANA Villas Bali、AYANA
                  Resort Bali和RIMBA by AYANA Bali。
                </li>
                <li>
                  AYANA Rewards积分的赚取和奖励兑换须遵守所有适用的法律法规。
                </li>
                <li>积分将四舍五入至最接近的整数积分。</li>
                <li>
                  税费、服务费、小费和其他费用不属于合资格附加费用类别，因此无资格赚取AYANA
                  Rewards积分。
                </li>
                <li>累积积分的有效期为最后活跃状态日期起的24个月。</li>
                <li>
                  如果会员注意到其AYANA
                  Rewards会员账户中有任何缺失积分，必须在退房日期/交易日期后的60天内通知AYANA
                  Rewards会员服务团队。未在此时间范围内通知会员服务团队将导致未能计入相关的AYANA
                  Rewards积分。
                </li>
                <li>
                  会员可以在退房/交易后的7个工作日后核实其积分状态。任何关于缺失AYANA
                  Rewards积分的索赔可以通过电子邮件发送至info@ayanarewards.com的AYANA
                  Rewards会员服务团队或通过其会员账户上的“缺失积分表”提交。
                </li>
                <li>
                  AYANA
                  Rewards保留在积分被错误计入账户的情况下从会员的账户余额中扣除积分的权利。
                </li>
              </ol>
              <h3>5.2 积分赚取 – 酒店和度假村</h3>
              <ol>
                <li>
                  AYANA
                  Rewards积分保留给在合资格房价期间遵循特定标准的会员。这些条件包括：
                  <ol type="a">
                    <li>会员必须在预订时注册为住宿客人。</li>
                    <li>
                      AYANA奖励积分可以在参与酒店的同一天最多为5个预订的房间获得。
                    </li>
                    <li>
                      所有五个房间必须与会员使用相同的会员编号或电子邮件地址注册。
                    </li>
                  </ol>
                </li>
                <li>
                  AYANA奖励会员仅作为房间的预订者但未亲自办理入住和入住参与酒店的，无法获得住宿期间房间和附加费用的AYANA奖励积分。
                </li>
                <li>
                  在所有参与酒店中，无论房费如何，通过任何不合资格预订渠道预订的会员无资格获得房间的合资格住宿夜数或积分。然而，会员仍可以在结算到房间的合资格附加费用中获得AYANA奖励积分。
                </li>
                <li>
                  如果会员未将合资格附加费用结算到房间，则会员需要通知参与品牌的酒店工作人员，他们希望从附加费用中获得AYANA奖励积分。
                </li>
                <li>
                  客人在成为AYANA奖励会员并保持活跃状态后，有资格获得AYANA奖励积分。积分将在退房后最多7个工作日内计入会员账户。
                </li>
                <li>
                  AYANA奖励积分赚取的合资格费用：
                  <ol type="a">
                    <li>
                      AYANA Midplaza Jakarta
                      <ol type="i">
                        <li>
                          房费包括提前入住、延迟退房和额外人员费用，但不包括日间使用。
                        </li>
                        <li>客房服务（不包括迷你吧）</li>
                        <li>
                          酒店管理的餐厅费用（不包括第三方餐厅，例如：本膳、角度）
                        </li>
                        <li>特定娱乐支出</li>
                        <li>水疗费用</li>
                      </ol>
                      <li>
                        Delonix Hotel Karawang
                        <ol type="i">
                          <li>
                            房费包括提前入住、延迟退房和额外人员费用，但不包括日间使用。
                          </li>
                          <li>客房服务（不包括迷你吧）</li>
                          <li>
                            酒店管理的餐厅费用（不包括第三方餐厅，例如：花火餐厅）
                          </li>
                          <li>特定娱乐支出</li>
                          <li>洗衣和交通</li>
                        </ol>
                        <li>
                          AYANA巴厘岛（AYANA巴厘岛别墅、AYANA塞加拉巴厘岛、AYANA巴厘岛度假村以及AYANA巴厘岛RIMBA）
                          <ol type="i">
                            <li>
                              房费包括提前入住、延迟退房和额外人员费用，但不包括日间使用。
                            </li>
                            <li>客房服务（不包括迷你吧）</li>
                            <li>
                              酒店管理的餐厅费用（不包括第三方餐厅，例如：本膳、Sumizen）
                            </li>
                            <li>特定娱乐支出</li>
                            <li>水疗费用</li>
                          </ol>
                          <li>
                            AYANA科莫多Waecicu海滩
                            <ol type="i">
                              <li>
                                房费包括提前入住、延迟退房和额外人员费用，但不包括日间使用。
                              </li>
                              <li>客房服务（不包括迷你吧）</li>
                              <li>
                                酒店管理的餐厅费用（不包括第三方餐厅，例如：本膳）
                              </li>
                              <li>特定娱乐支出</li>
                              <li>水疗费用</li>
                            </ol>
                          </li>
                          <li>
                            AYANA Lako di’a
                            <ol type="i">
                              <li>
                                仅通过个人直接预订的项目包费用和过夜租赁，以及包括酒精消费在内的任何其他消费
                              </li>
                            </ol>
                          </li>
                        </li>
                      </li>
                    </li>
                  </ol>
                </li>
              </ol>
              <h3>5.3 积分赚取 – 活动</h3>
              <p className="p--sm">
                AYANA奖励积分仅用于在Riverside高尔夫俱乐部支付并参与活动的玩家。客人通过注册AYANA奖励计划有资格累积分数，交易后7个工作日内将AYANA奖励积分计入其账户。在Riverside高尔夫俱乐部的AYANA奖励积分仅可由个人玩家获得。俱乐部举办的活动不符合积分赚取资格。
              </p>
              <ol>
                <ol>
                  <li>
                    AYANA奖励积分赚取的合资格费用：
                    <ol type="a">
                      <li>草坪费用</li>
                    </ol>
                  </li>
                  <li>
                    AYANA奖励积分赚取的非合资格费用：
                    <ol type="a">
                      <li>服务费</li>
                      <li>餐厅支出</li>
                      <li>水疗费用</li>
                      <li>零售购买</li>
                      <li>河畔（Riverside）高尔夫俱乐部会员购买</li>
                    </ol>
                  </li>
                </ol>
              </ol>
              <p className="p--sm" style={{ marginBottom: "24px" }}>
                在Riverside高尔夫俱乐部购买年度会员资格的会员有资格直接升级为AYANA奖励金卡，而那些选择长期会员资格的会员则可直接升级为AYANA奖励白金卡。
              </p>
              <Link
                id="ayana-rewards-redemption"
                href="#ayana-rewards-redemption"
                style={{
                  scrollMarginTop: "120px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                }}>
                6. AYANA奖励兑换
              </Link>
              <h3>6.1 一般条件</h3>
              <ol style={{ marginBottom: "24px" }}>
                <li>奖励证书的保管是会员的唯一责任。奖励证书将不予更换。</li>
                <li>
                  在AYANA奖励下收到的福利和奖励可能会被征税。这些税款是福利或奖励接收者的唯一责任。
                </li>
              </ol>
              <h3>6.2 兑换奖励凭证</h3>
              <ol>
                <li>
                  会员可在rewards.ayana.com的会员门户网站下的兑换部分兑换其AYANA奖励积分。除了会员的用户ID和密码，AYANA奖励保留为验证目的要求其他个人信息的权利。
                </li>
                <li>
                  兑换券必须在使用相关产品或服务之前兑换。会员必须出示以其电子邮件地址发送的打印或电子凭证作为兑换证明。
                </li>
                <li>
                  如果兑换券由非会员使用，会员必须事先通过书面电子邮件通知AYANA奖励会员客户服务。未能提供此信息可能导致酒店拒绝接收兑换凭证。
                </li>
                <li>
                  会员负责在索取奖励之前查阅每个奖励的条款和条件以及概述的程序。
                </li>
                <li>
                  要使用兑换券，会员必须通过发送电子邮件到info@ayanarewards.com进行预订，并注明其偏好的使用日期。在旺季/高峰期期间可能会收取额外费用。
                </li>
                <li>
                  所有兑换券均不予退还且不可取消。会员的AYANA奖励积分将在兑换时扣除，并将遵循兑换政策。
                </li>
                <li>
                  兑换券自兑换之日起有效期最长为6个月（180天）。然而，在某个特定时期，兑换券的有效期可能少于6个月。
                </li>
                <li>
                  有效期表示会员可以使用凭证的最后日期。对于免费住宿券，这指的是退房日期。对于非免费住宿券，它表示会员可以使用服务的最后日期。
                </li>
                <li>未使用的兑换券不可退还至会员账户、兑换现金或延长期限。</li>
                <li>
                  AYANA奖励不对兑换合作伙伴退出计划或合作伙伴奖励取消负责，这可能会影响提供的兑换。
                </li>
              </ol>
              <h3 style={{ fontWeight: "bolder" }}>6.3 积分支付</h3>
              <ol style={{ marginBottom: "24px" }}>
                <li>
                  会员可以使用通过积分支付功能所赚取的AYANA奖励积分在参与品牌预订房间（现金和积分）。
                </li>

                <li>在会员账户启用积分支付功能需要至少500个AYANA奖励积分。</li>
                <li>
                  会员可以选择使用其全部积分或积分与其他可用支付方式的组合。
                </li>
                <li>
                  积分支付仅适用于标记为“符合使用AYANA奖励积分资格”的房型和价格。
                </li>
                <li>
                  积分支付仅为通过https://www.ayana.com/、https://rewards.ayana.com/或https://www.delonixhotel.com/预订的会员提供。该功能不适用于通过线下合资格预订渠道进行的预订。
                </li>
                <li>积分支付不适用于会员未使用该功能进行的现有预订。</li>
                <li>
                  会员在入住期间可以从房价和附加费用中获得积分，积分将在退房后7个工作日内自动发放。
                </li>
                <li>
                  除非参与酒店明确允许，积分支付功能可能不适用于房间优惠或促销。
                </li>
                <li>
                  使用积分支付的会员必须遵守每个参与品牌设定的取消政策截止日期，如其预订中所述。未能遵守可能会导致罚款。
                </li>
                <li>
                  用于支付的积分将以积分的形式返回到会员账户。对于使用预订引擎上的其他可用方法进行的付款，退款将以预订时选择的相同付款方式发放。
                </li>
              </ol>
              <Link
                id="data-privacy"
                href="#data-privacy"
                style={{
                  scrollMarginTop: "120px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                }}>
                7. 数据隐私
              </Link>
              <ol style={{ marginTop: "7.5px", marginBottom: "24px" }}>
                <li>
                  AYANA奖励收集个人详细信息以进行会员管理和提升会员体验。这可能包括姓名、性别、电子邮件地址、国籍、生日、语言、电话号码、地址、居住国家、会员级别、积分活动、住宿历史和AYANA奖励网站登录活动以进行个性化。
                </li>
                <li>
                  收集的信息有助于有效的计划管理和福利的提供。联系方式使得能够发送计划更新和特别优惠。此外，数据有助于根据客人喜好个性化AYANA体验和改善服务。
                </li>
                <li>
                  在某些情况下，AYANA可能会与信任的第三方服务提供商共享个人信息，这些提供商协助计划运营。这些提供商遵循严格的数据安全协议。
                </li>
                <li>
                  客人有权访问、更正或删除其与计划相关的个人信息。此外，选择退出营销通信始终是一个选项。有关行使这些权利的明确说明在计划中提供。
                </li>
              </ol>
            </div>
          </div>
        </Layout>
      );
    } else {
      return (
        <Layout location={this.props.location}>
          <SEO
            title="Terms and Conditions "
            keyword="Terms and Conditions"
            decription="Terms and Conditions"
          />

          <div className="terms">
            <div className="container container--1000">
              <h1 className="text--center">条款与条件</h1>
              <p className="p--sm">
                阿雅娜会员礼遇是一项常客计划，专为奖励入住阿雅娜科莫多维艾齐洙沙滩度假村、阿雅娜
                Lako
                di'a、巴厘岛阿雅娜度假别墅、巴厘岛阿雅娜水疗度假酒店、巴厘岛金巴兰RIMBA酒店、雅加达阿雅娜中城广场酒店和加拉璜凤凰木酒店的宾客而设计。以下条款和条件旨在保护参与阿雅娜会员礼遇的会员。如果您需要更详细的说明，请即刻发送电子邮件至
                <a href="mailto:info@ayanarewards.com">info@ayanarewards.com</a>
                或登录<a href="https://ayanarewards.com">ayanarewards.com</a>。
              </p>
              <h3>会员资格</h3>
              <ol>
                <li>
                  所有个人宾客均有资格成为阿雅娜会员礼遇会员。阿雅娜会员礼遇资格不可转让。会员不得在多个帐户中保持会员资格或获得积分。
                </li>
                {/* <li>
                    会员资格在两（2）年内有效。会员帐户将被停用，如果这些年度没有活动，所有累计积分将被没收</li> */}
                <li>
                  当积分达到一定的标准时会员可以升级到下一级别。每个会员级别有效期为一年。一年累计到5000积分的会员可升级为金卡会员，且升级之后每年累计1500积分即可维持会员等级。一年累计到10000积分的会员可升级为白金卡会员，且升级之后每年累计5000积分即可维持会员等级。积分累计不足将导致会员等级下降。
                </li>
                <li>
                  如果没有准确完整地提供所需信息，阿雅娜会员礼遇保留拒绝/终止会员资格或拒绝任何申请的权利而无需给出任何理由。
                </li>
              </ol>
              <h3>赚取积分</h3>
              <ol>
                <li>
                  会员在参与酒店和商店每次有效的消费10,000印尼盾（不含税费和服务费）立即获得1个积分。会员在凤凰木酒店和公寓每消费15,000印尼盾（不含税费和服务费）立即获得1个积分。积分数字将向下舍入到最接近的整数。
                </li>
                <li>
                  积分仅适用于以符合条件的价格入住，停留和支付房费的会员。当（1）每个房间由会员支付并且所有费用在结账时结算时（2）一个房间被会员占用，会员可以在参与酒店获得相同入住日期的最多五个房间的积分。
                </li>
                <li>
                  阿雅娜会员优享计划积分有效期为产生积分后的10年或120个月。
                </li>
                <li>
                  对于所有酒店，无论预订房价如何，通过任何第三方网站（GDS除外）预订的会员均无资格获得其房间积分。所有会员仍可通过其客房其他符合资格的消费获得积分。
                </li>
                <li>
                  客人成为会员后即可获得积分。
                  <span
                    lang="ZH-CN"
                    style={{ fontSize: "11pt", fontFamily: "PMingLiU, serif" }}>
                    积分将在结账离店的
                  </span>
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Calibri, sans-serif",
                    }}>
                    7
                  </span>
                  <span
                    lang="ZH-CN"
                    new=""
                    style={{ fontSize: "11pt", fontFamily: "" }}
                    times="">
                    个工作日内到
                  </span>
                  <span
                    lang="ZH-CN"
                    style={{ fontSize: "11pt", fontFamily: "PMingLiU, serif" }}>
                    账
                  </span>
                  。
                </li>
                <li>
                  <ul>
                    <li>符合条件的消费：</li>
                    <li>
                      雅加达阿雅娜中城广场酒店
                      <br />
                      o大多数房价
                      <br />
                      本计划允许会员通过大多数房价赚取积分，除不适用房价外，如员工价、佣金价、批发价、免费住宿奖励、计入主帐户的房价、乘务员价、现金/积分房价和团体价。支付不适用房价的会员仍可通过符合条件的其他消费赚取积分。酒店有责任在其酒店系统内将这些房价标记为不可赚取积分。
                      <br />
                      o食品和饮品、排除 HonZen and KaDo 。<br />
                      o未入住费用、提前离开和取消费用
                    </li>
                    <li>
                      加拉璜凤凰木酒店
                      <br />
                      o所有消费均符合资格 (excluding Sakana Restaurant charges)
                    </li>
                    <li>
                      巴厘岛阿雅娜水疗度假酒店、巴厘岛阿雅娜度假别墅、巴厘岛金巴兰RIMBA酒店
                      <br />
                      o仅有个人直接预订房价符合资格,&nbsp;除特惠价项目外
                    </li>
                    <li>
                      阿雅娜Lako
                      Di'a号：套餐费用和游船包租只能通过个人直接预订进行，特别房价以及任何其他消费（包括酒精饮料消费）除外
                    </li>
                    <li>
                      **服务费、税费和其他杂费不适用于阿雅娜会员礼遇积分。
                    </li>
                  </ul>
                </li>
                <li>
                  会员可登录阿雅娜会员礼遇门户网站ayanarewards.com查看其阿雅娜会员礼遇积分余额和交易行为，或发送电子邮件至阿雅娜会员礼遇服务经理info@ayanarewards.com以获取最新信息。
                </li>
                <li>
                  积分未计入其阿雅娜会员礼遇计划的会员必须在退房日期后30天内通知阿雅娜会员礼遇服务经理。未通知会员服务团队将导致相关阿雅娜会员礼遇积分无法计入帐户。结账离店的7个工作日后会员可登陆会员账户查询积分累计
                  <br />
                  。任何关于未计入阿雅娜会员礼遇积分的申诉都可以发送电子邮件至ayanarewards@midplaza.com转发给阿雅娜会员礼遇会员服务部。
                </li>
                <li>
                  如果积分因各种问题记错到会员的账户上，会员理解阿雅娜会员优享计划具有全权把相应积分从会员的帐户余额中扣除。
                </li>
              </ol>
              <h3>领取奖励</h3>
              <ol>
                <li>
                  会员可登录ayanarewards.com在会员门户 &ndash;
                  奖励/兑换部分使用其阿雅娜会员礼遇积分兑换奖励。除会员用户名和密码外，阿雅娜会员礼遇保留要求获取其他个人信息以进行验证的权利。
                </li>
                <li>
                  最低阿雅娜会员礼遇积分适用于所有奖励。有关兑换要求请查看网站的奖励表。
                </li>
                <li>
                  在要求奖励之前，会员有义务始终参考每个奖励中所述的条款和条件。
                </li>
                <li>
                  对于夜间住宿奖励，需要通过发送电子邮件至info@ayanarewards.com进行预订，并说明首选入住日期。请注意，旺季期间可能需要额外收费。
                </li>
                <li>
                  所有奖励均不可退款且不可取消。会员积分将在兑换时扣除，并将遵循兑换政策。
                </li>
                <li>
                  自兑换日期起，奖励有效期最长为6个月。未使用的奖励不能退回会员账户或兑换现金。
                </li>
                <li>
                  阿雅娜会员礼遇不对兑换伙伴退出计划或合作伙伴取消奖励的事宜负责，这可能会影响所提供的奖励。
                </li>
              </ol>
              <h3>一般性规则</h3>
              <ol>
                <li>
                  当个人资料发生变更时，会员有责任在阿雅娜会员礼遇门户网站上更新其会员信息或以书面形式通知阿雅娜会员礼遇会员服务部。
                </li>
                <li>会员应全权负责保管其奖励证书。奖励证书不得补发。</li>
                <li>
                  阿雅娜会员礼遇会员服务办公室保留更正任何因处理错误而不正确的任何会员声明或阿雅娜会员礼遇积分收益或余额的权利。
                </li>
                <li>
                  阿雅娜会员礼遇保留修改规则、限制、计划权益和特别优惠的权利，恕不另行通知。
                  阿雅娜会员礼遇保留随时终止阿雅娜会员礼遇计划的权利。
                </li>
                <li>
                  阿雅娜会员礼遇的积分收益和奖励的兑换受所有适用法律和法规的约束。
                </li>
                <li>
                  从阿雅娜会员礼遇获取的福利和奖励可能需要缴纳税款。获取上述福利或奖励的会员应全权负责缴纳此税款。
                </li>
                <li>
                  阿雅娜会员礼遇不做任何明示性或暗示性保证或陈述，并明确拒绝对通过阿雅娜会员礼遇获取、兑换和/或享受的商品或服务的质量承担任何责任（包括间接损害）。在适用法律和法规允许下，任何法律暗示隐含的条件、保证或其他条款均尽最大程度排除。如果任何会员转让奖励证书的使用权，本条款也适用于受让人。
                </li>
                <li>
                  参与酒店和/或阿雅娜会员礼遇保留在必要时调整阿雅娜会员礼遇积分价值以反映不断变化的市场条件的权利。
                </li>
                <li>
                  有关阿雅娜会员礼遇或奖励使用的欺诈或滥用行为受到参与酒店和/或阿雅娜会员礼遇参与合作伙伴的适当行政和/或法律诉讼的约束。
                </li>
                <li>在法律禁止的情况下，本计划无效。</li>
                <li>
                  阿雅娜会员礼遇忠诚计划是由参与酒店执行的计划。服务提供商也可以访问会员记录。
                </li>
                <li>
                  对计划条款和条件的所有解释均由阿雅娜会员礼遇全权自行决定。如有任何争议，会员同意接受印度尼西亚法院的专属管辖权管辖。
                </li>
              </ol>
            </div>
          </div>
          <br />
        </Layout>
      );
    }
  }
}
